@import 'assets/styles/index.scss';

.TableFieldTable {

  & .ActionButton {
    padding: 4px 7px;
    width: 32px;
  }

  .ant-table-cell-with-append {
    display: flex;
    min-height: 75px;

    .TableField-Name {
      height: 30px;
      margin: 5px;
    }
  }

  .ant-table-row-expand-icon {
    padding: 7px !important;
    margin-top: 10px !important;
  }

  .no-spinners input {
    appearance: textfield;
    -moz-appearance: textfield;
  }
 
  .no-spinners input::-webkit-outer-spin-button,
  .no-spinners input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
} 