@import 'assets/styles/index.scss';

.Auth-layout {
	display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  font-size: 0.9rem;
}

.Auth-aside {
  display: flex;
  flex-direction: column;
  width: 40%;
	overflow-y: hidden !important;
	overflow-x: hidden !important;
}

.Auth-main {
  display: flex;
	flex-grow: 1;
	background-color: #f8f8f8;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.Auth-aside-container {
	display: flex;
	flex-direction: column;
	padding: 0px 40px;
	height: 100%;
	width: 400px;
	margin: 0 auto;
}

.Auth-aside-header {
	padding-top: 100px;
	text-align: center;
}

.Auth-aside-logo {
	height: 70px;
	width: auto;
}

.Auth-aside-body {
	margin-top: 150px;
}

.Auth-aside-footer {
	flex:2;
	display: flex;
	align-items: flex-end;
	margin: 0 auto;
	padding-bottom: 40px;
}

.Auth-aside-footer-wrapper {
	text-align: center;
}

.Login-google-auth {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	border: 1px solid rgba(0,0,0,.0625);
	border-radius: 4px;
	padding: 13px 30px 13px 16px;
	margin: 12px 0;
	width: 100%;
}

.Login-google-auth span {
	display: inline-block;
	width: 18px;
	height: 18px;
	background: 0 0;
	border: none;
	padding: 0;
	margin: 0;
	fill: #222325;
}

.Login-google-auth span img {
	float: left;
	height: 100%;
	width: 100%;
}

.Login-google-auth p {
	width: 100%;
	font-size: 16px;
	font-weight: 600;
	color: #7a7d85;
	padding: 0;
	margin-bottom: 0;
}

.divider {
	height: 12px;
	margin: 0 auto;
	border-bottom: 1px solid #e4e5e7;
	width: 50%;
}

.divider span {
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	text-transform: uppercase;
	padding: 0 8px;
	background-color: #f9fafb;
	color: #404145;
}

.auth-form .ant-input,
.auth-form .ant-input-affix-wrapper,
.auth-form .ant-input-password:hover,
.auth-form .ant-input-password:focus,
.auth-form .ant-input-affix-wrapper:focus,
.auth-form .ant-input-affix-wrapper .ant-input-affix-wrapper-focused .ant-input-password,
.auth-form .ant-form-item-has-error .ant-input,
.auth-form .ant-form-item-has-error .ant-input-affix-wrapper {
	border: none;
	border-radius: 0%;
	border-bottom: 1px solid #e4e5e7;
	transition: box-shadow .15s ease-in-out;
	padding: 1px 2px;
	background-color: transparent;
}

.auth-form .ant-input-password:hover,
.auth-form .ant-input:focus,
.auth-form .ant-input-affix-wrapper-focused,
.auth-form .ant-form-item-has-error .ant-input:focus,
.auth-form .ant-form-item-has-error .ant-input:hover,
.auth-form .ant-form-item-has-error .ant-input-affix-wrapper-focused {
	background-color: transparent;
	border-color: $blue;
	box-shadow: 0 3px 2px -2px rgba(0,123,255,.25);
}

.auth-form .ant-form-item-has-error .ant-input-password .ant-input {
	border-bottom: none;
}

.auth-form .ant-form-item-has-error .ant-input-affix-wrapper .ant-input-affix-wrapper-focused,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
	background-color: transparent !important;
	border-color: $blue !important;
}

.Auth-layout a {
	color: $blue;
}

@include to($breakpoint-md) {
	.Auth-aside {
		width: 100%;
  }

  .Auth-main {
    display: none;
  }
}